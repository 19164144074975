export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'name',
    sortable: true,
    sortField: 'name',
    label: 'field.name',
    stickyColumn: true,
    variant: 'light',
  },
  {
    key: 'email',
    label: 'field.email',
  },
  {
    key: 'status',
    sortable: true,
    sortField: 'isEnable',
    label: 'field.status',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.createDate',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
]
