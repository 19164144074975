<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      @search="search"
      @reset="reset"
      show-search
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col class="text-right" v-if="$can('create', 'administrator')">
            <b-button
              variant="primary"
              :to="{ name: 'create-administrator' }"
              >{{ $t("button.create") }}</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(profilePicture)="data">
            <b-avatar
              size="40"
              :src="data.item.mediaFile"
              :text="avatarText(data.item.userName)"
              variant="light-primary"
              class="badge-minimal"
            >
              <feather-icon v-if="!data.item.name" icon="UserIcon" size="22" />
            </b-avatar>
          </template>
          <template #cell(gender)="data">
            {{ $t("general." + data.item.gender) }}
          </template>
          <template #cell(status)="data">
            <b-badge
              pill
              variant="success"
              badge-glow
              v-if="data.item.isEnable"
            >
              {{ $t(`status.active`) }}
            </b-badge>
            <b-badge pill variant="danger" badge-glow v-else>
              {{ $t(`status.inactive`) }}
            </b-badge>
          </template>
          <template #cell(lastActiveAt)="data">
            {{ data.item.lastActiveAt | formatDate }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                :to="{
                  name: 'view-administrator',
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('general.view')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="$can('update', 'administrator')"
                :to="{
                  name: 'update-administrator',
                  params: { id: data.item.id },
                }"
                v-b-tooltip.hover
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NButtonDelete from "@/components/NButtonDelete";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import { avatarText } from "@core/utils/filter";

const UserRepository = Repository.get("user");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    activeSearch() {
      return (
        !!this.$route.query.name ||
        !!this.$route.query.email ||
        !!this.$route.query.phone ||
        !!this.$route.query.isEnable ||
        !!this.$route.query.username
      );
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        search: this.$route.query.search || null,
        limit: this.$store.state.pagination.perPage || 10,
      },
      avatarText,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search(searchText) {
      this.params.search = searchText;
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.params.search = "";
      this.$refs.search.reset();
      this.updateQuerySting();
      this.getData();
    },
    spliceItem(id) {
      const deleteIndex = this.items.findIndex((item) => {
        return item.id === id;
      });

      this.items.splice(deleteIndex, 1);
      this.total -= 1;
    },
    deleteItem(id) {
      UserRepository.delete(id)
        .then((response) => {
          this.spliceItem(id);
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.params,
      };
      UserRepository.index(params)
        .then((response) => {
          const data = response.data.data;
          this.items = [...data.list];
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const searchFields = SearchInputs;
    return {
      fields,
      searchFields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
